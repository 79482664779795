<template>
  <div style="min-height: 100%;background-color: #fff;">
    <div v-if="isMobile===false">
      <div class="box">
        <div class="ViewTitle">{{$t('user.myCollection')}}</div>
        <div class="contain">
          <div class="mainContent">
            <template v-if="!userInfo.userId">
              <div class="notLogin" v-if="languageSelect === 'zh'">
                <img src="@/assets/notLogin.png" alt="" />
                登录可查看更多收藏资讯
                <div style="cursor: pointer" @click="login">立即登录</div>
              </div>
              <div class="notLogin" v-if="languageSelect === 'en'">
                <img src="@/assets/notLogin.png" alt="" />
                Sign in for more subscription information
                <div style="cursor: pointer" @click="login">Log in</div>
              </div>
            </template>
            <template v-else>
              <div v-if="articles.length !== 0">
                <Collection :newList="articles" @cancle="cancleHandle"></Collection>
              </div>
              <template v-else>
                <div class="noData" v-if="languageSelect === 'zh'">
                  <img src="@/assets/none.png" alt="" />
                  暂未进行资讯收藏
                </div>
                <div class="noData" v-if="languageSelect === 'en'">
                  <img src="@/assets/none.png" alt="" />
                  Information collection is not currently available
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="m-collection-page">
        <Header :title="$t('user.myCollection')"></Header>
        <template v-if="!userInfo.userId">
          <div class="notLogin" v-if="languageSelect === 'zh'">
            <img src="@/assets/notLogin.png" alt="" />
            登录可查看更多收藏资讯
            <div style="cursor: pointer" @click="login">立即登录</div>
          </div>
          <div class="notLogin" v-if="languageSelect === 'en'">
            <img src="@/assets/notLogin.png" alt="" />
            Sign in for more subscription information
            <div style="cursor: pointer" @click="login">Log in</div>
          </div>
        </template>
        <template v-else>
          <div v-if="articles.length !== 0">
            <Collection :newList="articles" @cancle="cancleHandle"></Collection>
          </div>
          <template v-else>
            <div class="noData" v-if="languageSelect === 'zh'">
              <img src="@/assets/none.png" alt="" />
              暂未进行资讯收藏
            </div>
            <div class="noData" v-if="languageSelect === 'en'">
              <img src="@/assets/none.png" alt="" />
              Information collection is not currently available
            </div>
          </template>
        </template>
      </div>

      <Bottom></Bottom>
    </div>
      

        <!-- <AiPop @change="showDialog"></AiPop>
        <CancleTip
          :dialogVisible="cancleVisible"
          @hide="hide"
          @confirmDialog="confirmDialog"
        ></CancleTip>
        <BottomBanner v-if="isMobile === true">123</BottomBanner> -->
      <!-- </div> -->
  </div>
</template>
<script>
import { getNews, getReport, deleteReport, getAuthorize } from "@/api/user";
// import CancleTip from "@/components/CancleTip.vue";
// import AiPop from "@/components/AiPop.vue";
// import BottomBanner from "@/components/BottomBanner.vue";
import { shareUrl } from "@/utils/vxshare.js";
import Collection from "@/components/Collection.vue";
import Bottom from "@/components/mobile/Bottom.vue";
import Header from "@/components/mobile/Header.vue";

import { mapState } from 'vuex'

export default {
  name: "MySubscriptions",
  components: {
    // BottomBanner,
    Bottom,
    Collection,
    Header,
    // AiPop,
    // CancleTip,
  },
  data() {
    return {
      dialogVisible: false,
      isMobile: false,
      user: {
        userId: "",
        name: "",
      },
      languageSelect: "",
      cancleVisible: false,
      categoryRecommendList: null,
      PdfList: null,
      clickId: null,
      type: 1,
      titleEn: "",
      articles: [],
      isLoading: false
    };
  },
  // watch: {
  //   "$store.state.user"(newValue) {
  //     this.user = newValue;
  //   },
  // },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
    
    // if (this.$store.state.access_token) {
    //   // this.user = this.$store.state.user;
    //   this.getNews();
    //   this.getReport();
    // }
  },
  // activated(){
  //   this.getartical();
  // },
  computed: {
    ...mapState({
      'access_token': 'access_token', 
      'userInfo': 'user'
    })
  },
  created() {
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }

    this.getartical();

    let url = window.location.host;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "油气知识",
        text: "洞悉油气行业变化，体验AI大模型，就来油气知识！",
      };
      shareUrl(shareData);
    }, 1000);
  },
  methods: {
    cancleHandle(){
      console.log(111)
      this.getartical();
    },
    // 登录
    login() {
      // let href = "https://www.oilgasinfoai.com/?language=zh";
      let href = window.location.href;
      getAuthorize(href).then((res) => {
        console.log(res, "res");
        window.location.replace(res.data.url);
      });
    },
    showDialog(type) {
      this.dialogVisible = type;
    },
    async getNews() {
      try {
        const res = await getNews({
          limit: 3,
          userId: this.user.userId,
        });

        this.categoryRecommendList = res.data || [];
        if (this.PdfList == null) this.PdfList = [];

        const promises = this.categoryRecommendList.map(async (item) => {
          item.title = item.secondaryName;

          try {
            const response = await this.$axios.get(
              "/api/category/optionSelect",
              {
                params: {
                  level: 2,
                  categoryId: item.secondaryType,
                },
                headers: {
                  "Content-Type": "application/json",
                  Authorization: this.token,
                },
              }
            );

            item.titleEn = response.data.data[0].titleEn;
            this.$set(this, "titleEn", response.data.data.enTitle);
          } catch (error) {
            console.log(error);
          }
        });

        // 等待所有异步请求完成
        await Promise.all(promises);

        // 所有异步请求都成功完成后进行后续操作
        for (const item of this.categoryRecommendList) {
          if (this.languageSelect === "en") {
            item.title = item.titleEn;
          }
        }

        // 手动强制更新
        this.$forceUpdate();
      } catch (error) {
        console.log(error);
      }
    },

    // 获取二级分类yingwen
    async classfy(id) {
      try {
        const response = await this.$axios.get("/api/category/optionSelect", {
          params: {
            level: 2,
            categoryId: id,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        });

        this.titleEn = response.data.data[0].titleEn;

        return this.titleEn; // 返回获取到的 titleEn
      } catch (error) {
        console.log(error);
        throw error; // 抛出错误
      }
    },

    getReport() {
      getReport({
        limit: 6,
        userId: this.user.userId,
      })
        .then((res) => {
          this.PdfList = res.data || [];
          if (this.categoryRecommendList == null)
            this.categoryRecommendList = [];
          this.PdfList.forEach((item) => {
            item.title = item.secondaryName;
            if (item.secondaryName === "中文期刊") {
              item.titleEn = "Chinese Journals";
            } else {
              item.titleEn = "English Journals";
            }
            item.list = item.reportList;
          });
        })
        .catch(() => {});
    },
    Delete1(id) {
      this.clickId = id;
      this.cancleVisible = true;
      this.type = 1;
    },
    Delete2(id) {
      this.clickId = id;
      this.cancleVisible = true;
      this.type = 2;
    },
    hide() {
      this.cancleVisible = false;
    },
    confirmDialog() {
      deleteReport(this.clickId).then(() => {
        this.cancleVisible = false;
        //跳转页面或执行方法
        if (this.type == 1) {
          let index = this.categoryRecommendList.findIndex(
            (item) => item.id === this.clickId
          );
          this.categoryRecommendList.splice(index, 1);
        } else {
          let index = this.PdfList.findIndex(
            (item) => item.id === this.clickId
          );
          this.PdfList.splice(index, 1);
        }
        this.clickId = null;
        this.$message({
          message: "取消订阅成功！", //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {},
        });
      });
    },
    getartical() {
      console.log(this.user);
      console.log(typeof this.user.userId);
      console.log("111111111111");
      this.$axios
        .get("/api/collect/selectCollectByUserId", {
          params: { userId: this.userInfo.userId },
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((response) => {
          this.articles = response.data.data.collectList;
          console.log(this.articles);
          // 从响应数据中获取 collectList
          // 在这里对返回的 collectList 进行处理，例如赋值给 this.articles 等操作
          // this.articles = collectList; // 例如将 collectList 赋值给 articles
        })
        .catch((error) => {
          // 处理错误
          console.error("查询失败", error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  background: #fff;
  min-height: 100%;

  .ViewTitle {
    height: 67px;
    line-height: 67px;
    border-bottom: 1px solid #ebebeb;
    font-size: 20px;
    color: #333333;
    font-weight: 400;
    padding: 0 30px;
  }

  .contain {
    margin: 0 30px;
    padding-bottom: 64px;
    padding-top: 30px;
  }
}
.flexBox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.artical {
  width: 96%;
  border: none;
  border-radius: 0px;
  border-bottom: rgba(44, 62, 80, 0.66) 1px dashed;
  height: 130px;
  margin-left: 2%;

  //box-shadow:  0 0 0.1em 0 rgba(240, 76, 76, 0.5);
}

.artical1 {
  width: 96%;
  border: none;
  border-radius: 0px;
  border-bottom: rgba(44, 62, 80, 0.66) 1px dashed;
  height: 125px;
  margin-left: 2%;
  //box-shadow:  0 0 0.1em 0 rgba(240, 76, 76, 0.5);
}

.detailContent {
  margin-left: 7.5%;
  width: 85%;
  min-height: 94vh;
  height: auto;
}
.marginTop {
  margin-top: 30px;
}

.marginTop1 {
  margin-top: 10px;
}

.centerWidth1 {
  width: 98%;
  margin: 0 auto 60px auto;
}

.mainContent1 {
  flex: 1;
}

.flexBox1 {
  margin-top: 10px;
}

.limitTitLe {
  margin-left: 35px;
  font-size: 18px;
  color: #333333;
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.limitTitLe:hover {
  color: var(--main-color);
}

.limitTitLe1 {
  display: flex;
  justify-content: space-between;
  margin-left: 35px;
  font-size: 18px;
  width: 45%;
  color: #333333;
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.limitTitLe1:hover {
  color: var(--main-color);
}


.m-collection-page{
  background-color: #f8f8f8;
}
</style>

