<template>
  <div>
    <div v-if="isMobile === false" class="newBoxList">
      <!-- <div class="allTitle">
        <div class="left" v-if="languageSelect === 'zh'">
          <div class="title">收藏</div>
        </div>
      </div> -->
      <div class="list">
        <div v-for="(item, index) in articles" :key="index">
          <div class="content" v-if="languageSelect === 'zh'">
            <div class="title">
              <div class="oneLine" @click="toDetail(item)">
                {{ item.infoNews.title }}
              </div>
            </div>
            <div class="cancelStyle" @click="cancel(item)">取消收藏</div>
          </div>
          <div class="content" v-if="languageSelect === 'en'">
            <div class="title">
              <div class="oneLine" @click="toDetail(item)">
                {{ item.infoNews.titleEn || item.infoNews.title }}
              </div>
            </div>
            <div class="cancelStyle" @click="cancel(item)">Unfavorite</div>
          </div>
        </div>
      </div>
      <InfoTip
        :dialogVisibleInfo="dialogVisibleInfo"
        @hideDialog="hideDialog"
      ></InfoTip>
    </div>
    <div v-if="isMobile === true" class="m-collection-list">
      <div
        class="m-collection-item"
        v-for="(item, index) in articles"
        :key="index"
        @click="toDetail(item)"
      >
        <img src="" alt="">
        <div class="mci-right">
          <div class="mci-title twoLine">{{ item.infoNews[trans('title,titleEn')] ? item.infoNews[trans('title,titleEn')] : item.infoNews.title }}</div>
          <div class="mci-bottom">
            <span class="mci-time">{{ item.infoNews.createTime ? item.infoNews.createTime.split(' ')[0] : ''}}</span>
            <span class="mci-cancel-btn" @click.stop="cancel(item)">{{ $t('unfavorite') }}</span>
          </div>
        </div>
        <!-- <div class="content" v-if="languageSelect === 'en'">
          <div class="title">
            <div class="oneLine">
              {{ item.infoNews.titleEn }}
            </div>
          </div>
          <div class="cancelStyle">Unfavorite</div>
        </div> -->
      </div>
    </div>
    <InfoTip
      :dialogVisibleInfo="dialogVisibleInfo"
      @hideDialog="hideDialog"
    ></InfoTip>
  </div>
</template>
<script>
import InfoTip from "@/components/InfoTip.vue";
import { mapState } from 'vuex'
import mixins from "@/utils/mixins.js";

export default {
  name: "Like",
  mixins: [mixins],
  components: {
    InfoTip,
  },
  props: {
    newList: {
      type: Array,
      required: true,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    articles(){
      return this.newList
    },
    ...mapState({
      'access_token': 'access_token', 
      'userInfo': 'user'
    })
  },
  data() {
    return {
      isMobile: true,
      languageSelect: "",
      // baseURL: this.$utils.baseURL,
      num: 86,
      titleEn: "",
      contentEn: "",
      dialogVisibleInfo: false,
      // articles: [],
      userId: localStorage.getItem("userId"),
      hideDialog: false,
      user: {
        userId: "",
        name: "",
      },
    };
  },
  created() {
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    // this.getartical();
    // this.articles = this.newList
  },
  methods: {
    toDetail(item){
      let map = {
        1: 'technology',
        4: 'news',
        2: 'report',
        3: 'opportunity'
      }
      this.$router.push('/'+ map[item.infoNews.primaryTypeid] +'/' + item.infoNews.id);
    },
    // 取消收藏
    cancel(item) {
      this.$axios
        .post("/api/collect/addOrDelete", {
          newsId: item.infoNews.id,
          userId: this.userInfo.userId,
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then(() => {
          // this.getartical();
          this.$emit('cancle')
          console.log("取消成功");
        })
        .catch((error) => {
          // 处理错误
          console.error("查询失败", error);
        });
    },
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
    // this.getartical();
  },
  watch: {
    // "$store.state.user"(newValue) {
    //   this.user = newValue;
    // },
  },
};
</script>
<style lang="scss" scoped>
.newBoxList {
  width: 100%;
  background: #fff;
  // padding: 30px 20px;

  .allTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cecece;

    .left {
      display: flex;
      align-items: center;

      .title {
        font-size: 20px;
        color: #333333;
        font-weight: 500;
        position: relative;
        padding-bottom: 10px;
      }

      .title::before {
        content: "";
        width: 100%;
        height: 2px;
        background: var(--main-color);
        position: absolute;
        left: 0;
        bottom: -1px;
      }

      .cancel {
        margin-left: 40px;
        font-size: 12px;
        width: 100px;
        height: 26px;
        text-align: center;
        line-height: 26px;
        border-radius: 13px;
        border: 1px solid var(--main-color);
        color: var(--main-color);
        margin-top: -9px;
      }

      .cancel:hover {
        background: var(--main-color);
        color: white;
      }
    }

    .more {
      font-size: 12px;
      color: #333333;
      padding-bottom: 10px;

      a {
        display: block;
      }
    }

    .more:hover {
      color: var(--main-color);
    }
  }

  .list {
    // padding-top: 15px;
    // display: grid;
    // grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    // gap: 20px;
    list-style: none;

    .content {
      display: flex;

      .cancelStyle {
        margin-left: 10px;
        font-size: 18px;
        width: 120px;
        height: 26px;
        text-align: center;
        line-height: 26px;
        border-radius: 13px;
        border: 1px solid var(--main-color);
        color: var(--main-color);
        cursor: pointer;
        margin-top: 6px;
      }

      .title {
        flex: 1;
        width: 450px;
        font-size: 18px;
        color: #333333;
        font-weight: 500;
        line-height: 26px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        margin-top: 6px;
      }

      .title:hover {
        color: var(--main-color);
      }

      .textBox {
        position: relative;
        // max-width: 100%;
        // width: fit-content;
        padding-right: 5em;

        .text {
          color: #666666;
          font-size: 14px;
          font-weight: 400;
          line-height: 26px;
          min-height: 26px;
        }

        .text:hover::before,
        .text:hover::after {
          color: var(--main-color);
        }

        .two {
          max-height: 52px;
          word-wrap: break-word;
          word-break: break-all;
          background: #ffffff;
          color: #ffffff;
          overflow: hidden;
          line-height: 26px;
        }

        .three {
          max-height: 78px;
          word-wrap: break-word;
          word-break: break-all;
          background: #ffffff;
          color: #ffffff;
          overflow: hidden;
          line-height: 26px;
        }

        .two::before,
        .three::before,
        .two::after,
        .three::after {
          content: attr(title1);
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          color: #434343;
          line-height: 26px;
        }

        .two::before {
          display: block;
          overflow: hidden;
          z-index: 1;
          max-height: 26px;
          height: 100%;
          background: #ffffff;
        }

        .two::after {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: 52px;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          box-sizing: border-box;
          text-indent: -5em;
          padding-right: 5em;
        }

        .three::before {
          display: block;
          overflow: hidden;
          z-index: 1;
          max-height: 52px;
          height: 100%;
          background: #ffffff;
        }

        .three::after {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: 78px;
          -webkit-line-clamp: 3;
          text-overflow: ellipsis;
          box-sizing: border-box;
          text-indent: -10em;
          padding-right: 5em;
        }

        span {
          // background: #fff;
          position: absolute;
          bottom: 0;
          right: 0;
          color: #333333;
          font-size: 12px;
          line-height: 26px;
          padding-left: 10px;
          z-index: 1;
        }

        span:hover {
          color: var(--main-color);
        }
      }
    }

    .item {
      margin-top: 20px;
      justify-content: space-between;
      align-items: flex-start;

      .image {
        width: 171px;
        height: 120px;
        margin-right: 20px;
        overflow: hidden;
      }
    }

    .title::before {
      content: " ";
      width: 6px;
      height: 6px;
      border-radius: 3px;
      display: inline-block;
      background: var(--main-color);
      margin-right: 5px;
    }

    .item:not(:first-child) {
    }
  }
}

.newBoxList1 {
  width: 100%;
  margin-top: 10px;
  background: #fff;
  padding: 10px 5px;

  .allTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cecece;

    .left {
      display: flex;
      align-items: center;

      .title {
        font-size: 18px;
        color: #333333;
        font-weight: 600;
        position: relative;
        padding-bottom: 5px;
      }

      .title::before {
        content: "";
        width: 100%;
        height: 2px;
        background: var(--main-color);
        position: absolute;
        left: 0;
        bottom: -1px;
      }

      .cancel {
        margin-left: 10px;
        font-size: 18px;
        width: 120px;
        height: 26px;
        text-align: center;
        line-height: 26px;
        border-radius: 13px;
        border: 1px solid var(--main-color);
        color: var(--main-color);
        margin-top: -9px;
      }

      .cancel:hover {
        background: var(--main-color);
        color: white;
      }
    }

    .more {
      font-size: 14px;
      color: #333333;
      padding-bottom: 5px;

      a {
        display: block;
      }
    }

    .more:hover {
      color: var(--main-color);
    }
  }

  .list {
    padding-top: 15px;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 20px;

    .content {
      display: flex;
      flex: 1;

      .cancelStyle {
        margin-left: 10px;
        font-size: 18px;
        width: 25vw;
        height: 26px;
        text-align: center;
        line-height: 26px;
        border-radius: 13px;
        border: 1px solid var(--main-color);
        color: var(--main-color);
        margin-top: 6px;
      }

      .title {
        width: 70vw;
        font-size: 16px;
        color: #333333;
        font-weight: 500;
        line-height: 18px;
        display: flex;
        align-items: center;
      }

      .title:hover {
        color: var(--main-color);
      }

      .textBox {
        position: relative;
        // max-width: 100%;
        // width: fit-content;
        padding-right: 5em;

        .text {
          color: #666666;
          font-size: 14px;
          font-weight: 400;
          line-height: 26px;
          min-height: 26px;
        }

        .text:hover::before,
        .text:hover::after {
          color: var(--main-color);
        }

        .two {
          max-height: 52px;
          word-wrap: break-word;
          word-break: break-all;
          background: #ffffff;
          color: #ffffff;
          overflow: hidden;
          line-height: 26px;
        }

        .three {
          max-height: 78px;
          word-wrap: break-word;
          word-break: break-all;
          background: #ffffff;
          color: #ffffff;
          overflow: hidden;
          line-height: 26px;
        }

        .two::before,
        .three::before,
        .two::after,
        .three::after {
          content: attr(title1);
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          color: #434343;
          line-height: 26px;
        }

        .two::before {
          display: block;
          overflow: hidden;
          z-index: 1;
          max-height: 26px;
          height: 100%;
          background: #ffffff;
        }

        .two::after {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: 52px;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          box-sizing: border-box;
          text-indent: -5em;
          padding-right: 5em;
        }

        .three::before {
          display: block;
          overflow: hidden;
          z-index: 1;
          max-height: 52px;
          height: 100%;
          background: #ffffff;
        }

        .three::after {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: 78px;
          -webkit-line-clamp: 3;
          text-overflow: ellipsis;
          box-sizing: border-box;
          text-indent: -10em;
          padding-right: 5em;
        }

        span {
          // background: #fff;
          position: absolute;
          bottom: 0;
          right: 0;
          color: #333333;
          font-size: 12px;
          line-height: 26px;
          padding-left: 10px;
          z-index: 1;
        }

        span:hover {
          color: var(--main-color);
        }
      }
    }

    .item {
      margin-top: 20px;
      justify-content: space-between;
      align-items: flex-start;

      .image {
        width: 171px;
        height: 120px;
        margin-right: 20px;
        overflow: hidden;
      }
    }

    .title::before {
      content: " ";
      width: 6px;
      height: 6px;
      border-radius: 3px;
      display: inline-block;
      background: var(--main-color);
      margin-right: 5px;
    }

    .item:not(:first-child) {
    }
  }
}

.m-collection-list{
  margin: 0 20px;
  overflow: hidden;
  min-height: 74vh;
  .m-collection-item{
    display: flex;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
    line-height: 1;
    justify-content: space-between;
    & > img{
      width: 120px;
      height: 70px;
      vertical-align: top;
    }
    .mci-right{
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;
      .mci-title{
        font-size: 14px;
        line-height: 1.4;
        font-weight: 600;
        &:active{
          color: var(--main-color);
        }
      }
      .mci-bottom{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .mci-time{
          font-size: 12px;
          color: #999;
        }
        .mci-cancel-btn{
          border-radius: 30px;
          border: 1px solid var(--main-bg-color);
          padding: 4px 12px;
          font-size: 12px;
          color: var(--main-bg-color);
        }
      }
    }
  }
}
</style>

